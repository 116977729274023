@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  html.dark *.not-themed {
    --color-primary-950: 67 20 7;
    --color-primary-900: 124 45 18;
    --color-primary-800: 154 52 18;
    --color-primary-700: 194 65 12;
    --color-primary-600: 234 88 12;
    --color-primary-500: 249 115 22;
    --color-primary-400: 251 146 60;
    --color-primary-300: 253 186 116;
    --color-primary-200: 254 215 170;
    --color-primary-100: 255 237 213;
    --color-primary-50: 255 247 237;

    --color-gray-50: 249 250 251;
    --color-gray-100: 243 244 246;
    --color-gray-200: 229 231 235;
    --color-gray-300: 209 213 219;
    --color-gray-400: 156 163 175;
    --color-gray-500: 107 114 128;
    --color-gray-600: 75 85 99;
    --color-gray-700: 55 65 81;
    --color-gray-800: 31 41 55;
    --color-gray-900: 17 24 39;
    --color-gray-950: 3 7 18;


    --color-white: 255 255 255;
    --color-black: 0 0 0;

    --color-base-50: 248 250 252;
    --color-base-100: 241 245 249;
    --color-base-200: 226 232 240;
    --color-base-300: 203 213 225;
    --color-base-400: 148 163 184;
    --color-base-500: 100 116 139;
    --color-base-600: 71 85 105;
    --color-base-700: 51 65 85;
    --color-base-800: 30 41 59;
    --color-base-900: 15 23 42;
    --color-base-950: 2 6 23;
    
    @apply text-base-900;
  }

  html.dark :not(.not-themed)
  {
    --color-white: 0 0 0;
    --color-black: 255 255 255;

    --color-primary-50: 67 20 7;
    --color-primary-100: 124 45 18;
    --color-primary-200: 154 52 18;
    --color-primary-300: 194 65 12;
    --color-primary-400: 234 88 12;
    --color-primary-500: 249 115 22;
    --color-primary-600: 251 146 60;
    --color-primary-700: 253 186 116;
    --color-primary-800: 254 215 170;
    --color-primary-900: 255 237 213;
    --color-primary-950: 255 247 237;

    --color-gray-950: 249 250 251;
    --color-gray-900: 243 244 246;
    --color-gray-800: 229 231 235;
    --color-gray-700: 209 213 219;
    --color-gray-600: 156 163 175;
    --color-gray-500: 107 114 128;
    --color-gray-400: 75 85 99;
    --color-gray-300: 55 65 81;
    --color-gray-200: 31 41 55;
    --color-gray-100: 17 24 39;
    --color-gray-50: 3 7 18;

    --color-base-950: 248 250 252;
    --color-base-900: 241 245 249;
    --color-base-800: 226 232 240;
    --color-base-700: 203 213 225;
    --color-base-600: 148 163 184;
    --color-base-500: 100 116 139;
    --color-base-400: 71 85 105;
    --color-base-300: 51 65 85;
    --color-base-200: 30 41 59;
    --color-base-100: 15 23 42;
    --color-base-50: 2 6 23;
  }
}

html,
body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply bg-base-100 scroll-smooth text-base-900;
}

input {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  @apply transition-all rounded-[8px] p-4 text-base-900 border border-base-200 bg-base-50;
}

textarea {
  @apply bg-base-50;
}

input::placeholder {
  @apply text-base-400;
}

button:disabled {
  @apply cursor-default grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  @apply text-base-900;
}

h2 {
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0em;
  @apply text-base-900;
}

.desc {
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  @apply text-base-400;
}

h3 {
  @apply text-xl font-semibold text-black;
}

h4 {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
}

p,
div,
h1,
h2,
h3,
h4,
h5,
h6,
img {
  user-select: none;
  -webkit-user-drag: none;
}

p {
  @apply text-sm text-black;
  font-weight: 400;
}

.checkbox-square {
  padding: 0px;
  -webkit-appearance: initial;
  appearance: initial;
  width: 14px;
  height: 14px;
  background: white;
  border: 2px solid gray;
  border-radius: 3px;
  position: relative;
}

.button-primary {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  border-radius: 32px;
  transition: 100ms all ease;
  @apply bg-primary-500 text-white cursor-pointer py-3 px-4;
}

.button-primary:hover {
  @apply bg-primary-200 text-primary-600;
}

.button-primary:disabled {
  @apply bg-base-200 text-base-400;
}

.button-primary:disabled:hover {
  @apply bg-base-200 text-base-400 cursor-default;
}

.button-primary::after {
  content: "-->";
  @apply ml-1 transition-all;
}

.button-primary:hover::after {
  content: "-->";
  @apply translate-x-1;
}

.button-add {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  border-radius: 32px;
  transition: 100ms all ease;
  @apply text-primary-600 bg-white space-x-1 cursor-pointer;
}

.button-add:hover {
  @apply bg-base-100;
}

.button-secondary {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  border-radius: 32px;
  transition: 100ms all ease;
  @apply text-primary-500 bg-white border border-base-200 cursor-pointer px-4 py-3;
}

.button-secondary:hover {
  @apply bg-primary-200;
}

.button-secondary:disabled {
  @apply bg-base-200 text-base-400;
}

.button-dark {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  border-radius: 32px;
  transition: 100ms all ease;
  @apply text-white bg-base-900 border border-white cursor-pointer;
}

.button-dark:hover {
  @apply bg-base-600;
}

.button-sidebar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 16px 24px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  transition: 100ms all ease;
  @apply bg-white text-base-950 space-x-3 cursor-pointer;
}

.button-sidebar>span {
  flex-grow: 1;
}

.button-sidebar:hover,
.button-sidebar.active {
  @apply bg-primary-100 text-primary-500;
}

.button-sidebar.active {
  @apply border-l-2 border-l-primary-600 bg-primary-50;
}

.button-orange {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  border-radius: 6px;
  transition: 100ms all ease;
  @apply bg-orange-500 text-white cursor-pointer !shadow-none;
}

.button-orange:hover {
  border-radius: 12px;
  @apply bg-orange-600;
}

.button-orange:disabled {
  @apply bg-base-200 text-base-400;
}

.button-orange:disabled:hover {
  @apply bg-base-200 text-base-400 cursor-default;
}

.button-orange-b {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  border-radius: 6px;
  transition: 100ms all ease;
  @apply border border-orange-500 text-white cursor-pointer;
}

.button-orange-b:hover {
  border-radius: 12px;
  @apply bg-white-50;
}

.link {
  @apply font-normal text-sm text-white;
}

.link:hover {
  @apply text-orange-400;
}

.link.active {
  @apply text-orange-600;
}

section.sidebar {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  @apply text-base-400 px-6 py-4 mt-6;
}

section.box {
  @apply rounded-[12px] overflow-hidden;
}

header {
  position: sticky;
  margin-top: 0px !important;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  @apply text-base-950 bg-base-100 p-6;
}

section.box>header:first-of-type {
  position: relative;
  padding-top: 16px !important;
  top: 0px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  @apply border-b border-b-gray-200 my-0 p-5 bg-white flex justify-between items-center;
}

section>table {
  @apply rounded-t-none w-full;
}

table {
  @apply bg-white rounded-t-[12px] overflow-hidden;
}

table td {
  @apply p-5;
}

table>thead td {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  @apply text-base-400;
}

table>tbody td {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  @apply text-base-900;
}

table>thead {
  @apply border-b border-b-gray-200;
}

table>tbody>tr:not(:first-of-type) {
  @apply border-t border-t-gray-200;
}

.checkbox-square:checked {
  background: royalblue;
}

.modal {
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: min-content;
  overflow: hidden;
  @apply bg-white;
}

.modal>header {
  margin-top: 0 !important;
  padding-top: 16px !important;
  @apply w-full p-5 border-b border-b-base-200 bg-white flex justify-between items-start my-0;
}

.modal>header>div {
  @apply flex flex-col space-y-0 justify-start items-start;
}

.modal>header h1 {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  margin: 0;
  @apply text-base-900;
}

.modal>header h3 {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  @apply text-base-400;
}

.modal>div:nth-child(2) {
  overflow-y: auto;
  @apply flex-grow p-5 flex flex-col space-y-3;
}

.modal>footer {
  @apply w-full p-5 border-t border-t-base-200 flex justify-between items-start;
}

span.tag {
  display: flex;
  width: max-content;
  text-align: center;
  padding: 6px 12px;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  border-radius: 32px;
}

div.toggle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  gap: 10px;
  border-radius: 8px;
  @apply border border-base-200 bg-white;
}

div.toggle>span {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

div.action-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  padding: 20px;
}

div.action-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  padding: 20px;
}

.pagination-button {
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  @apply w-10 h-10 rounded-full flex justify-center items-center text-base-900 bg-white border border-base-200 transition-all;
}

.pagination-button:hover {
  @apply bg-base-100;
}

.pagination-button:disabled {
  @apply bg-base-200;
}

.pagination-button:active:disabled {
  @apply bg-base-200;
}

.pagination-button:active {
  @apply bg-primary-600 text-white;
}

.pagination-button>svg {
  @apply fill-base-900;
}

.pagination-button:active>svg {
  @apply fill-white;
}

.pagination-button.active:disabled {
  @apply bg-base-200;
}

.pagination-button.active {
  @apply bg-primary-600 text-white;
}

.pagination-button.active>svg {
  @apply fill-white;
}

.pnotify-error>.pnotify-container {
  @apply rounded-xl border-2 border-rose-600 bg-white;
}

.pnotify-text {
  @apply text-base-900;
}

.pnotify {
  z-index: 10000000000 !important;
  background-color: transparent;
}

#tos a {
  @apply text-orange-500 hover:underline;
}

#tos * {
  @apply text-base-950;
}

#tos li::before {
  content: " - ";
}

#tos ul {
  @apply text-left;
  text-indent: 1rem;
}

#tos p {
  @apply text-justify leading-6 select-text;
}

#tos h1 {
  @apply mb-8 text-base-700 text-center;
}

#tos h2 {
  @apply mb-4 mt-8 text-base-700 text-center;
}

#tos h3 {
  @apply mb-4 mt-8 text-base-700 text-center;
}

#tos h4 {
  @apply mb-4 mt-8 text-lg text-base-700 text-center;
}

#tos .madewith {
  @apply w-full flex justify-center hidden;
}

#pp li {
  @apply text-orange-500 hover:underline text-center;
}

#pp a {
  @apply text-orange-500 hover:underline;
}

#pp * {
  @apply text-base-950;
}

#pp p {
  @apply text-justify leading-6;
}

#pp ul {
  @apply text-center;
}

#pp h1 {
  @apply mb-8 text-base-700 text-center;
}

#pp h2 {
  @apply mb-4 mt-8 text-base-700 text-center;
}

#pp h3 {
  @apply mb-4 mt-8 text-base-700 text-center;
}

#pp h4 {
  @apply mb-4 mt-8 text-lg text-base-700 text-center;
}

#pp .madewith {
  @apply w-full flex justify-center hidden;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.animate-fade-in {
  animation: fadeIn 0.3s ease-in-out;
}

select {
  @apply text-base-900 bg-white cursor-pointer;
}

select option {
  @apply text-base-900 bg-white;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.faq {
  @apply relative;
}

.faq:not(:last-of-type) {
  @apply border-b border-b-slate-200
}

.faq>div:first-child {
  position: relative;
  z-index: 100;
}

.faq>div:first-child>svg {
  transition: all 0.3s ease;
}

.faq.open>div:first-child>svg {
  transform: rotate(180deg);
}

.faq>div>div:not(:first-child) {
  transition: all 0.3s ease;
  @apply h-0 pb-0;
}

.faq.open>div>div:not(:first-child) {
  @apply h-[max-content] pb-6;
}

.faq>div:not(:first-child) {
  transition: all 0.3s ease;
  opacity: 0;
}

.faq.open>div:not(:first-child) {
  transition: all 0.3s ease;
  opacity: 1;
}