.tag-lime {
    @apply text-lime-500 bg-lime-100;
}

html.dark .tag-lime {
    @apply text-lime-400 bg-lime-900;
}

.tag-amber {
    @apply bg-amber-50 text-amber-500
}

html.dark .tag-amber {
    @apply text-amber-400 bg-amber-900;
}

.tag-orange {
    @apply bg-orange-50 text-orange-500
}

html.dark .tag-orange {
    @apply text-orange-400 bg-orange-900;
}

.tag-indigo {
    @apply bg-indigo-50 text-indigo-500
}

html.dark .tag-indigo {
    @apply text-indigo-400 bg-indigo-900;
}

.tag-green {
    @apply bg-green-100 text-green-500
}

html.dark .tag-green {
    @apply text-green-400 bg-green-900;
}

.tag-cyan {
    @apply bg-cyan-50 text-cyan-500
}

html.dark .tag-cyan {
    @apply text-cyan-400 bg-cyan-900;
}

.tag-blue {
    @apply bg-blue-100 text-blue-500
}

html.dark .tag-blue {
    @apply text-blue-400 bg-blue-900;
}

.tag-red {
    @apply bg-red-100 text-red-500
}

html.dark .tag-red {
    @apply text-red-400 bg-red-900;
}